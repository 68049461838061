import { Trans, useLingui } from "@lingui/react";
import Col from "react-bootstrap/Col";
import Offcanvas from "react-bootstrap/Offcanvas";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
import { selectCurrentViewLanguage } from "../../features/viewSlice";
import { useListEvaluationEmissionDetailsQuery } from "../../services/api";
import { useRequireAuthenticated } from "../../services/hooks";
import { ghgInventorySummary } from "../../utils/ghgInventory";
import { DoughnutChart } from "../elements/DoughnutChart";
import EmissionsTable from "../shared/EmissionsTable";
import { LoadingSpinner } from "../elements/LoadingSpinner";
import AssignmentBenchmarkChart from "../shared/AssignmentBenchmarkChart";
import { cropLabel } from "../../utils/crops";

export function AssignmentDetail({
    assignment,
    survey,
    setSelectedAssignment,
}) {
    const { i18n } = useLingui();
    const currentLanguage = useSelector(selectCurrentViewLanguage);
    useRequireAuthenticated();

    const latestEvaluation = assignment?.latest_evaluation;

    const {
        data: emissionDetails,
        isLoading: emissionDetailsIsLoading,
        error: emissionDetailsError,
    } = useListEvaluationEmissionDetailsQuery(
        {
            evaluationId: latestEvaluation?.id,
        },
        { skip: !latestEvaluation }
    );

    const summary = ghgInventorySummary(
        latestEvaluation,
        emissionDetails,
        i18n,
        currentLanguage
    );
    const chartData = Object.keys(summary).map((key) => ({
        label: summary[key].label,
        value: summary[key].tonne,
    }));

    return (
        <Offcanvas
            show={!!assignment}
            onHide={() => setSelectedAssignment(null)}
            className="offcanvas-50"
            placement="end"
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    {assignment?.farm?.name}
                    <br />
                    <span className="fs-6">{survey?.title}</span>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {emissionDetailsIsLoading && (
                    <Row className="mb-4">
                        <Col>
                            <LoadingSpinner inline={true} />
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col>
                        <h3 className="mt-0 mb-3">
                            {1}. <Trans id="form_result.section.summary" />
                        </h3>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <h4>
                            <Trans id="form_result.emission_by_source" />
                        </h4>
                        <h5 className="mb-3">
                            kg CO<sub>2</sub>e{" "}
                            <Trans id="form_result.emission_by_source.per_tonne" />{" "}
                            {cropLabel(
                                assignment?.survey?.crop,
                                currentLanguage
                            )}
                        </h5>
                        {assignment && <DoughnutChart data={chartData} />}
                        {!assignment && (
                            <p className="text-center my-5">
                                <Trans id="survey.select_assessment" />
                            </p>
                        )}
                    </Col>
                    <Col lg={8}>
                        <EmissionsTable
                            assignment={assignment}
                            evaluation={latestEvaluation}
                            emissionDetails={emissionDetails}
                        />
                    </Col>
                </Row>
            </Offcanvas.Body>
        </Offcanvas>
    );
}
