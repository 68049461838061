import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Tooltip,
} from "chart.js";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectCurrentViewLanguage } from "../../features/viewSlice";
import { formatNumber } from "../../utils/format";

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    BarController,
    CategoryScale,
    LinearScale,
    BarElement
);

const StackedBarChart = ({ data }) => {
    const currentLanguage = useSelector(selectCurrentViewLanguage);

    const chartRef = useRef(null); // Reference to the canvas element
    const chartInstanceRef = useRef(null); // Reference to the chart instance

    useEffect(() => {
        // Clean up the previous chart instance when the component unmounts or rerenders
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, []);

    useEffect(() => {
        const options = {
            responsive: true,
            aspectRatio: 1.3,
            interaction: {
                intersect: false,
                mode: "index",
            },
            plugins: {
                legend: {
                    position: "bottom",
                    // maxWidth: 300,
                    align: "start",
                    labels: {
                        font: { size: 12 },
                        usePointStyle: true,
                        pointStyle: "rectRounded",
                    },
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            if (context.dataset.label) {
                                const label = context.dataset.label;
                                const value = context.parsed.y;
                                return `${label}: ${formatNumber(
                                    value,
                                    2
                                )} kg CO2e`;
                            }
                            return "";
                        },
                    },
                },
            },
            scales: {
                x: {
                    stacked: true,
                    grid: {
                        display: true,
                    },
                },
                y: {
                    stacked: true,
                    grid: {
                        display: false,
                    },
                },
            },
        };

        // Create a new chart instance
        if (chartRef.current) {
            if (!chartInstanceRef.current) {
                chartInstanceRef.current = new ChartJS(chartRef.current, {
                    type: "bar",
                    data: data,
                    options: options,
                });
            } else {
                chartInstanceRef.current.data = data;
                // TODO: bugfix labels not changing upon change of language
                // chartInstanceRef.current.options = {...options};
                chartInstanceRef.current.update("none");
            }
        }
    }, [data, currentLanguage]);

    return (
        <div>
            <canvas ref={chartRef}></canvas>
        </div>
    );
};

export default StackedBarChart;
