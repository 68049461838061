import {
    ArcElement,
    Chart as ChartJS,
    DoughnutController,
    Legend,
    Tooltip,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { CHART_COLORS } from "../../utils/charts";

ChartJS.register(ArcElement, Tooltip, Legend, DoughnutController);

export function DoughnutChart({ data }) {
    const dataInternal = {
        labels: data.map((d) => d.label),
        datasets: [
            {
                label: "Abc",
                data: data.map((d) => d.value),
                backgroundColor: CHART_COLORS.map((c) => c[0]),
                borderColor: CHART_COLORS.map((c) => c[1]),
                borderWidth: 1,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    font: { size: 12 },
                    usePointStyle: true,
                    pointStyle: "rectRounded",
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.label}: ${Math.round(
                            context.parsed
                        )} kg CO2e`;
                    },
                },
            },
        },
        //aspectRatio: 0.6,
    };

    return <Doughnut data={dataInternal} options={options} />;
}
