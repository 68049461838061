import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { get, useFormContext, Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { selectCurrentViewLanguage } from "../../features/viewSlice";
import { fieldOptionLabel } from "../../utils/forms";
import { InfoButton } from "./InfoButton";

export function BaseNumericInput({
    name,
    as,
    label,
    unit,
    hint,
    placeholder,
    form,
    info,
    decimalScale,
}) {
    const currentLanguage = useSelector(selectCurrentViewLanguage);
    const {
        register,
        trigger,
        formState: { errors },
        control,
    } = form ? form : useFormContext();

    const isMultiUnit = unit && Array.isArray(unit);

    const error = get(errors, name);

    const controlType = "text";
    const asElement = {
        customInput: Form.Control,
        decimalSeparator: ".",
        allowedDecimalSeparators: [",", "."],
        decimalScale: decimalScale ? decimalScale : 2,
        thousandSeparator: " ",
        valueIsNumericString: true,
    };

    return (
        <Form.Group as={as} controlId={name}>
            <Form.Label>{label}</Form.Label>

            <InputGroup>
                {info && (
                    <InfoButton
                        infoTitle={info?.title}
                        infoText={info?.text}
                    ></InfoButton>
                )}
                <Controller
                    name={name}
                    render={({
                        field: { name, onChange, onBlur, value, ref },
                    }) => (
                        <NumericFormat
                            {...asElement}
                            name={name}
                            isInvalid={error}
                            onValueChange={(value, sourceInfo) => {
                                onChange(value.value);
                            }}
                            onBlur={onBlur}
                            value={value}
                            getInputRef={ref}
                        />
                    )}
                    {...controlType}
                    placeholder={placeholder}
                    aria-label={label}
                    aria-describedby={`${name}-unit`}
                    control={control}
                />

                {unit && !isMultiUnit && (
                    <InputGroup.Text id={`${name}-unit`}>
                        {unit}
                    </InputGroup.Text>
                )}
                {unit && isMultiUnit && (
                    <Form.Select
                        id={`${name}-unit`}
                        className="input-group-unit"
                        aria-label={`${label} Unit`}
                        {...register(`${name}__unit`, {
                            deps: [name],
                            onChange: () =>
                                trigger(name, { shouldFocus: true }),
                        })}
                    >
                        {unit.map((u, i) => (
                            <option key={`${name}__unit-${i}`} value={u.name}>
                                {fieldOptionLabel(u, currentLanguage)}
                            </option>
                        ))}
                    </Form.Select>
                )}

                {error && (
                    <Form.Control.Feedback type="invalid">
                        {error?.message}
                    </Form.Control.Feedback>
                )}
            </InputGroup>
            {hint && <Form.Text className="text-muted">{hint}</Form.Text>}
        </Form.Group>
    );
}
