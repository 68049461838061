import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { get, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectCurrentViewLanguage } from "../../features/viewSlice";
import { InfoButton } from "./InfoButton";

export function BaseInput({
    name,
    as,
    asTextarea,
    registerOptions,
    label,
    hint,
    placeholder,
    form,
    info,
}) {
    const currentLanguage = useSelector(selectCurrentViewLanguage);
    const {
        register,
        trigger,
        formState: { errors },
        control,
    } = form ? form : useFormContext();

    const error = get(errors, name);

    let controlType = { type: "text" };
    let asElement = {};
    if (asTextarea) {
        controlType = {};
        asElement = { as: "textarea", rows: 3 };
    }

    return (
        <Form.Group as={as} controlId={name}>
            <Form.Label>{label}</Form.Label>

            <InputGroup>
                {info && (
                    <InfoButton
                        infoTitle={info?.title}
                        infoText={info?.text}
                    ></InfoButton>
                )}
                <Form.Control
                    {...controlType}
                    placeholder={placeholder}
                    {...register(name, {
                        ...registerOptions,
                    })}
                    isInvalid={error}
                    aria-label={label}
                    aria-describedby={`${name}-unit`}
                    {...asElement}
                />

                {error && (
                    <Form.Control.Feedback type="invalid">
                        {error?.message}
                    </Form.Control.Feedback>
                )}
            </InputGroup>
            {hint && <Form.Text className="text-muted">{hint}</Form.Text>}
        </Form.Group>
    );
}
