import { boolean, number, object, string } from "yup";
import { yupTestPostCode } from "../../utils/schemaUtils";

export const metaGenericSchema = function (
    { surveyParams, farm },
    { selectOptions }
) {
    const group = "meta_generic";

    const usePostcodePositionSystem =
        surveyParams?.POSITION_SYSTEM == "postcode";
    const useGpsPositionSystem =
        !surveyParams?.POSITION_SYSTEM ||
        surveyParams?.POSITION_SYSTEM == "gps";

    const enableFacilityName = surveyParams?.ENABLE_FACILITY_NAME == "true";
    const disableClimateZone = surveyParams?.DISABLE_CLIMATE_ZONE == "true";

    const enableFieldBuyerId = surveyParams?.ENABLE_FIELD_BUYER_ID == "true";
    const buyerIdLabel = surveyParams?.BUYER_ID_LABEL;
    const buyerIdLabel__de = surveyParams?.BUYER_ID_LABEL__DE;

    const enableFieldSupplierId =
        surveyParams?.ENABLE_FIELD_SUPPLIER_ID == "true";
    const supplierIdLabel = surveyParams?.SUPPLIER_ID_LABEL;
    const supplierIdLabel__de = surveyParams?.SUPPLIER_ID_LABEL__DE;

    const data = object({
        farm_name: string().required(),
        ...(enableFacilityName && {
            facility_name: string().required(),
        }),
        ...(enableFieldBuyerId && {
            buyer_id: string().required().oneOfOptions("buyer_id"),
        }),
        ...(enableFieldSupplierId && {
            supplier_id: string().required(),
        }),
        country: string().required().oneOfOptions("country"),
        ...(!disableClimateZone && {
            climate_zone: string().required().oneOfOptions("climate_zone"),
        }),
        ...(usePostcodePositionSystem && {
            postcode: string().required().min(3).max(15).test({
                name: "valid-postcode",
                test: yupTestPostCode,
                message: "Invalid post code for country.",
            }),
            postcode_not_found: boolean(),
            postcode_geocoding_failed: boolean().oneOf([false]),
        }),
        latitude: number().required().min(-90).max(90),
        longitude: number().required().min(-180).max(180),
    });

    const defaults = {
        farm_name: farm?.name,
        country: farm?.country,
        climate_zone: surveyParams?.CFT_CLIMATE_ZONE || "",
    };

    const ui = {
        type: "section",
        name: group,
        label__de: "Betriebsdaten",
        label: "Farm data",
        children: [
            {
                type: "text",
                name: `${group}.farm_name`,
                label: "Farm name",
                label__de: "Betriebsname",
                widthLg: 5,
                styleBreakRowAfter: true,
            },
            enableFacilityName && {
                type: "text",
                name: `${group}.facility_name`,
                label: "Facility name",
                label__de: "Name der Anlage bzw. des Verarbeitungsstandorts",
                widthLg: 5,
                styleBreakRowAfter: true,
            },
            enableFieldBuyerId && {
                type: "select_one",
                name: `${group}.buyer_id`,
                label: `${buyerIdLabel}`,
                label__de: `${buyerIdLabel__de}`,
                options: selectOptions("buyer_id"),
            },
            enableFieldSupplierId && {
                type: "text",
                name: `${group}.supplier_id`,
                label: `${supplierIdLabel}`,
                label__de: `${supplierIdLabel__de}`,
            },
            {
                type: "select_one",
                name: `${group}.country`,
                label: "Country",
                label__de: "Land",
                widthLg: 3,
                options: selectOptions("country"),
            },
            usePostcodePositionSystem && {
                type: "postcode",
                name: `${group}.postcode`,
                label: "Post Code / ZIP Code",
                label__de: "Postleitzahl",
                widthLg: 3,
                styleBreakRowAfter: true,
                parameters: () => ({
                    countryFieldName: `${group}.country`,
                    latitudeFieldName: `${group}.latitude`,
                    longitudeFieldName: `${group}.longitude`,
                    postcodeNotFoundFieldName: `${group}.postcode_not_found`,
                    postcodeGeocodingFailedFieldName: `${group}.postcode_geocoding_failed`,
                }),
            },
            {
                type: "number",
                name: `${group}.latitude`,
                label: "GPS coordinate latitude",
                label__de: "GPS-Koordinate Breitengrad (Latitude)",
                hint: "Example: -33.779147 (Cape Town, South Africa)",
                hint__de: "Beispiel: 53.5283 (Altes Land, Niedersachsen)",
                decimalScale: 10,
                widthLg: 3,
                condition: (watch) =>
                    useGpsPositionSystem ||
                    watch(`${group}.postcode_not_found`) == true,
            },
            {
                type: "number",
                name: `${group}.longitude`,
                label: "GPS coordinate longitude",
                label__de: "GPS-Koordinate Längengrad (Longitude)",
                hint: "Example: 18.953296 (Cape Town, South Africa)",
                hint__de: "Beispiel: 9.661076 (Altes Land, Niedersachsen)",
                decimalScale: 10,
                widthLg: 3,
                condition: (watch) =>
                    useGpsPositionSystem ||
                    watch(`${group}.postcode_not_found`) == true,
                styleBreakRowAfter: true,
            },
            !disableClimateZone && {
                type: "select_one",
                name: `${group}.climate_zone`,
                label: "Climate zone",
                label__de: "Klimazone",
                widthLg: 4,
                options: selectOptions("climate_zone"),
                styleBreakRowAfter: true,
                /*                 info: {
                    title: "How to identify your climate zone?",
                    text: "A world map with the climate zones can be found in Volume 4, Chapter 3 on page 47 of the IPCC 2019 refinements: https://www.ipcc-nggip.iges.or.jp/public/2019rf/pdf/4_Volume4/19R_V4_Ch03_Land%20Representation.pdf",
                    title__de: "Wie finde ich meine Klimazone?",
                    text__de:
                        "Eine Weltkarte mit Klimazonen findet sich in Volume 4, Kapitel 3 auf Seite 47 der IPCC 2019 refinements: https://www.ipcc-nggip.iges.or.jp/public/2019rf/pdf/4_Volume4/19R_V4_Ch03_Land%20Representation.pdf",
                }, */
            },
        ],
    };

    return { group, data, ui, defaults };
};
