import { Trans, useLingui } from "@lingui/react";
import { useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
import { selectCurrentViewLanguage } from "../../features/viewSlice";
import {
    useGetAssessmentStatisticsByIdQuery,
    useGetSurveyAssignmentStatisticsByIdQuery,
} from "../../services/api";
import { useAuthToken } from "../../services/hooks";
import { obtainChartColor } from "../../utils/charts";
import { cropLabel } from "../../utils/crops";
import { mapEmissionSource } from "../../utils/ghgInventory";
import { LoadingSpinner } from "../elements/LoadingSpinner";
import StackedBarChart from "../elements/StackedBarChart";

export default function AssignmentBenchmarkChart({ assignment }) {
    const { i18n } = useLingui();
    const currentLanguage = useSelector(selectCurrentViewLanguage);
    const authToken = useAuthToken();

    const [referenceUnit, setReferenceUnit] = useState("per_kg"); // or "per_ha"

    const query = authToken // choose API endpoint depending on token auth or logged in user
        ? useGetAssessmentStatisticsByIdQuery
        : useGetSurveyAssignmentStatisticsByIdQuery;
    const {
        data: statistics,
        error,
        isLoading,
    } = query({
        id: assignment.id,
        authToken: authToken,
    });

    const prepareStatisticsDataForChart = (emissionList, multiplyBy = 1) => {
        const emissionsObj = {};
        if (!emissionList) return emissionsObj;

        for (let e of emissionList) {
            if (!(e.emission_source in emissionsObj))
                emissionsObj[e.emission_source] = 0;

            emissionsObj[e.emission_source] += e.emissions_co2e_kg * multiplyBy;
        }
        return emissionsObj;
    };
    const preparedData = useMemo(() => {
        return {
            self_emissions: {
                per_kg: prepareStatisticsDataForChart(
                    statistics?.self_emissions?.per_kg?.details,
                    1000
                ),
                per_ha: prepareStatisticsDataForChart(
                    statistics?.self_emissions?.per_ha?.details
                ),
            },
            average_emissions: {
                per_kg: prepareStatisticsDataForChart(
                    statistics?.average_emissions?.per_kg?.details,
                    1000
                ),
                per_ha: prepareStatisticsDataForChart(
                    statistics?.average_emissions?.per_ha?.details
                ),
            },
            top_emissions: {
                per_kg: prepareStatisticsDataForChart(
                    statistics?.top_emissions?.per_kg?.details,
                    1000
                ),
                per_ha: prepareStatisticsDataForChart(
                    statistics?.top_emissions?.per_ha?.details
                ),
            },
        };
    }, [statistics]);

    const chartData = useMemo(() => {
        const dataLabelOwn = i18n._("form_result.benchmark.dataset_name_own");
        const dataLabelAvg = i18n._("form_result.benchmark.dataset_name_avg");
        const dataLabelTop = i18n._("form_result.benchmark.dataset_name_top");

        const allEmissionSources = Object.keys({
            ...preparedData?.self_emissions[referenceUnit],
            ...preparedData?.average_emissions[referenceUnit],
            ...preparedData?.top_emissions[referenceUnit],
        });
        const data = [
            { x: dataLabelOwn, ...preparedData?.self_emissions[referenceUnit] },
            {
                x: dataLabelAvg,
                ...preparedData?.average_emissions[referenceUnit],
            },
            { x: dataLabelTop, ...preparedData?.top_emissions[referenceUnit] },
        ];
        const datasets = allEmissionSources.map((e, i) => {
            const color = obtainChartColor(i);
            return {
                label: mapEmissionSource(e, i18n),
                data: data,
                backgroundColor: color?.backgroundColor,
                borderColor: color?.borderColor,
                borderWidth: 1,
                parsing: {
                    yAxisKey: e,
                },
            };
        });
        return {
            labels: [dataLabelOwn, dataLabelAvg, dataLabelTop],
            datasets: datasets,
        };
    }, [preparedData, referenceUnit]);

    const noDataAvailableYet =
        !isLoading && (error || !statistics?.average_emissions);

    return (
        <>
            {isLoading && <LoadingSpinner inline={true} />}
            {noDataAvailableYet && (
                <p>
                    <Trans id="form_result.benchmark.insufficient_data_text" />
                </p>
            )}

            {!isLoading && !noDataAvailableYet && (
                <>
                    <Row className="mb-1">
                        <Col md="7" className="mt-4 mb-0">
                            <h4 className="mb-0 ms-5">
                                <Trans id="form_result.benchmark.emissions_header" />
                            </h4>

                            <h5 className="my-1 fw-normal ms-5">
                                kg CO<sub>2</sub>e{" "}
                                {referenceUnit == "per_kg" && (
                                    <Trans id="form_result.benchmark.button.per_tonne" />
                                )}
                                {referenceUnit == "per_ha" && (
                                    <Trans id="form_result.benchmark.button.per_hectare" />
                                )}{" "}
                                {cropLabel(
                                    assignment?.survey?.crop,
                                    currentLanguage
                                )}
                            </h5>
                        </Col>
                        <Col className="my-0"></Col>
                    </Row>
                    <Row>
                        <Col md="7" className="my-0">
                            <StackedBarChart data={chartData} />
                        </Col>
                        <Col className="my-0">
                            <h4>
                                <Trans id="form_result.benchmark.description_header" />
                            </h4>
                            <p>
                                <Trans id="form_result.benchmark.description_text" />
                            </p>
                            <div className="w-100"></div>
                            <ButtonGroup
                                aria-label="Select unit for chart"
                                size="sm"
                                className="my-1"
                            >
                                <Button
                                    variant="outline-primary"
                                    onClick={() => setReferenceUnit("per_kg")}
                                    active={referenceUnit == "per_kg"}
                                >
                                    <Trans id="form_result.emission_by_source.table.per_tonne" />
                                </Button>
                                <Button
                                    variant="outline-primary"
                                    onClick={() => setReferenceUnit("per_ha")}
                                    active={referenceUnit == "per_ha"}
                                >
                                    <Trans id="form_result.emission_by_source.table.per_hectare" />
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
}
